import React from "react";
import Card from "../../components/card";
import Hero from "../../components/hero";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const Divider = ({ value }) => {
    const spaceDivider = `pt-${value?.espacio_arriba || '4'} pb-${value?.espacio_abajo || '4'}`;
    const styleDivider = `w-6/12 mx-auto border-gray-400`;
    return (
        <div className={spaceDivider}>
            <div className={styleDivider} style={{ borderTopWidth: value?.grosor || '2' }} />
        </div>
    );
};

const ImageWithText = ({ value, invertir }) => {
    const img = (
        <img className="w-1/2 rounded-md shadow-sm mb-4 m-auto block px-2"
             alt={value.imagen.title}
             src={value.imagen.large.url} />
    );
    const txt = (
        <p className="w-1/2 px-2" dangerouslySetInnerHTML={{ __html: value.texto }} />
    );
    return (
        <div className="my-8 text-justify w-11/12 md:w-6/12 mx-auto">
            <div className="inline-flex items-start gap-x-8">
                {invertir ? txt : img}
                {invertir ? img : txt}
            </div>
        </div>
    );
};

function StreamField({ value }) {
    const { body: streamField, title } = value;
    const isFirstComponentHero = streamField[0]?.type === 'hero';

    return (
        <>
            {!isFirstComponentHero ? (
                <div>
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 text-3xl sm:w-1/2 ml-4 mt-12 lg:mt-24 sm:mx-auto">
                        {title}
                    </h1>
                </div>
            ) : null}

            {streamField.map((field, index) => {
                switch (field.type) {
                    case 'hero':
                        // If the first component is a hero, render it, otherwise skip rendering hero components
                        return (
                            <div key={index}>
                                <Hero {...field.value} />
                            </div>
                        );
                    case 'card':
                        return (
                            <div key={index} className="my-8 flex justify-center w-11/12 mx-auto">
                                <Card {...field.value} />
                            </div>
                        );
                    case 'p':
                        return (
                            <div key={index} className="my-8 text-justify w-11/12 md:w-6/12 mx-auto" dangerouslySetInnerHTML={{ __html: field.value }} />
                        );
                    case 'image_w_text':
                        return <ImageWithText key={index} {...field.value} invertir={field.value.invertir} />;
                    case 'divider':
                        return <Divider key={index} value={field.value} />;
                    case 'image_list':
                        return (
                            <div key={index} className="sm:w-8/12 md:w-6/12 mx-auto px-4">
                                <Slide easing="ease">
                                    {field.value.map((image, imgIndex) =>
                                        <div key={imgIndex} className="flex flex-wrap justify-center content-center gap-x-8 gap-y-12 mt-4">
                                            <img className="rounded-md shadow-sm mb-4 m-auto block"
                                                 alt={image.title}
                                                 src={image.large.url} />
                                        </div>
                                    )}
                                </Slide>
                            </div>
                        );
                    case 'image':
                        return (
                            <div key={index} className="sm:w-4/12 mx-auto px-4">
                                <div className="flex flex-wrap justify-center content-center gap-x-8 gap-y-12 mt-4">
                                    <img className="rounded-md shadow-sm mb-4 m-auto block"
                                         alt={field.value.title}
                                         src={field.value.large.url} />
                                </div>
                            </div>
                        );
                    case 'card_list':
                        return (
                            <div key={index} className="md:w-10/12 mx-auto">
                                <div className="flex flex-wrap justify-center content-center gap-x-8 gap-y-12 mt-4">
                                    {field.value.map((card, cardIndex) => <Card key={cardIndex} {...card} />)}
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            })}
            <div className="mt-20" />
        </>
    );
}

export { StreamField };
