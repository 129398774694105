/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {UserGroupIcon} from '@heroicons/react/outline'
import moment from 'moment'
import 'moment/locale/es';


export default class DetailModal extends React.Component{
  constructor(props) {
      
      super(props)
      this.state = {open: false, 
        dia: '--/--/----'}
      this.cancelButtonRef = React.createRef()
      this.apuntarseRef = React.createRef()
  }
  
  passClassInfo = (class_info) => {
    console.info('class info')
    console.info(class_info)

    let titulo = `${class_info.nombre_clase} a las ${class_info.hora}`

    let horaLocal = moment(class_info.dia)
    
    this.setState({...this.state, 
        open: true,
        dia: horaLocal.locale('es').format('dddd DD MMMM'),
        apuntados: class_info.apuntados,
        aforo: class_info.aforo,
        coste_creditos: class_info.coste_creditos,
        titulo: titulo,
        clase_id: class_info.clase_id,
        user_inside: class_info.user_inside
    })
  }

      setOpen = (apuntarse) => {
          if (apuntarse)
              this.props.apuntarseClase(this.state.clase_id, this.state.user_inside)
          this.setState({
              open: false,
            })
      }


    
  render() {
      
    return (
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.apuntarseRef}
            open={this.state.open}
            onClose={this.setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
              </Transition.Child>
    
              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <UserGroupIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                          {this.state.titulo}
                        </Dialog.Title>
                        <div className="mt-2 font-semibold text-gray-600 text-sm grid grid-cols-6">
                            <span className="font-semibold  col-span-2">Apuntados</span>
                            <span className="font-regular col-span-1">{this.state.apuntados}</span>
                            <span className="font-semibold  col-span-2">Aforo</span>
                            <div className="font-regular col-span-1">{this.state.aforo}</div>
                        </div>
                        <div className="mt-2">

                              { this.state.user_inside ?
                                  <div className="mt-2">
                                    <p className="text-sm text-gray-500">¿Estás seguro de querer cancelar tu asistencia a {this.state.titulo} el dia {this.state.dia}?</p>
                                    <p className="text-sm font-lg font-bold text-blue-500"> Si la cancelación se produce 12 horas antes del inicio de la clase, se te reembolsará <span className="font-bold">{this.state.coste_creditos} crédito{this.state.creditos > 0 ? 's' : ''}</span></p>
                                  </div>
                                  :
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">¿Estás seguro que quieres apuntarte el día <span className="font-semibold text-green-500">{this.state.dia}</span> ?</p>
                                    <p className="text-sm font-lg text-green-500"> Te costará <span className="font-bold">{this.state.coste_creditos} crédito{this.state.creditos > 0 ? 's' : ''}</span></p>
                                </div>
                              }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                      <button
                      type="button"
                      className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  ${this.state.user_inside ? 'bg-indigo-400 hover:bg-indigo-700' : 'bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:bg-green-700'} sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={() => this.setOpen(true)}
                      ref={this.apuntarseRef}
                    >
                      {this.state.user_inside ? 'Desapuntarme' : 'Me apunto' }
                    </button>
                    <button
                      type="button"
                      className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 ${this.state.user_inside ? 'ring-2 ring-offset-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500' : '' } sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={() => this.setOpen(false)}
                      ref={this.cancelButtonRef}
                    >
                        {this.state.user_inside ? 'Volver' : 'Cancelar' }
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )
    }
}