import ClassCalendar from "./Calendar";
import ListModal from "./JoinSchedule";
import {handleResponse} from "../../internals/authservice/authentication.service";
import * as Constants from "../../internals/constants";

export default class AttendancesCalendar extends ClassCalendar {

    clickedSchedule = (scheduleInfo) => {

      fetch(`${Constants.api_url}/clases/list/${scheduleInfo.schedule.body.clase_id}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => {
               this.modalRef.current.passClassInfo(data)
            })
        
    }

    render(modal = <ListModal ref={this.modalRef} apuntarseClase={this.apuntarseClase}/>) {
        return super.render(modal);
    }

}