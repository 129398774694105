
import '../../assets/css/index.css';

import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from '../../assets/images/half-logo.png'
import {Link, NavLink} from 'react-router-dom';
import { authenticationService } from "../authservice/authentication.service";

const navigation = [
    { name: 'Tarifas', href: '/blog/tarifas', current: true },
    { name: 'Clases de escalada', href: '/blog/clases-de-escalada', current: true },
    { name: 'Escuela Infantil', href: '/blog/escuela-infantil', current: true },
    { name: 'Instalaciones', href: '/blog/instalaciones', current: true },
]

const profile_nav = [
    { name: 'Calendario de Clases', href: '/calendario', current: true },
    { name: 'Perfil', href: '/perfil', current: true },
    { name: 'Accesos', href: '/accesos', current: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TopBar() {
  return (
    <Disclosure as="nav" className="bg-gray-700 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to='/'>{<img src={Logo} alt="Moai Climbing Logo" className="h-10"></img>}</Link>
                  
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink  activeClassName="text-bold" key={item.name} to={item.href} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{item.name}</NavLink>
                   
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/*<button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">*/}
                {/*  <span className="sr-only">View notifications</span>*/}
                {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                {/*</button>*/}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="hover:bg-gray-700 px-2 bg-gray-800 flex text-sm rounded-full focus:ring-white">
                          <span className="text-gray-300  hover:text-white px-3 py-2 rounded-md text-sm font-medium">Cliente</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                        >
                            {profile_nav.map((item) => (
                                 <Menu.Item>
                                      <NavLink  activeClassName="font-bold underline" key={item.name} to={item.href} className="block px-4 py-2 text-sm text-gray-700 border-b-2 border-gray-100 mb-2">{item.name}</NavLink>
                                 </Menu.Item>
                            ))}


                          <Menu.Item>
                                { authenticationService.currentUserValue !== null  ? <a
                                href="/"
                                className={classNames(

                                  'block px-4 py-2 text-sm text-gray-700 border-gray-100 bg-blue-50 -mt-2 text-bold'
                                )}
                                onClick={() => {authenticationService.logout()}}
                              >
                                Cerrar Sesión

                              </a>

                                :
                                <a
                                href="/login"
                                className={classNames(

                                  'block px-4 py-2 text-sm text-gray-700 bg-blue-50 -mt-2 border-gray-50 font-bold'
                                )}

                              >
                                Iniciar Sesión

                              </a>
                                }

                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden z-50">
            <div className="px-2 pt-2 pb-3 space-y-1 z-50">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


