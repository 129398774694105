import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react'

let failure_icon = (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle"
                         className="w-8 h-8 mr-2 mx-auto fill-current" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 576 512">
    <path fill="currentColor"
    d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
</svg>)

let success_icon = (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle"
                    className="w-8 h-8 mr-2 mx-auto fill-current" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
    <path fill="currentColor"
          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
</svg>)


export class SuccessPopClass extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {show: false};
        this.timeout = null;
    }

    toggle = () => {
        this.setState({show: true})
        this.timeOut()
    }

    timeOut = () => {
        this.timeout = setTimeout(() => this.setState({show: false}), 5000);
    }
    
    hide = () => {
        this.setState({...this.props, show: !this.state.show})
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        return (

            this.props.message ?

            <Transition.Root show={this.state.show} as={Fragment} 
            enter="transition-opacity duration-12"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"R
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
             
                <div
                    onClick={this.hide}
                    className="z-50 fixed flex w-full m-8 sm:max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-800" style={{bottom: '0%', left:' 50%', transform: 'translate(-50%, -50%)'}}>
                <div className={ this.props.error ? "w-12 pt-4 bg-red-400" : "w-12 pt-4 bg-green-600" }>
                    { this.props.error  ? failure_icon: success_icon}
                </div>

                <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                        <span className={this.props.error ? "font-semibold " : "font-semibold "}>{this.props.title}</span>
                        <p className="text-sm text-gray-600 dark:text-gray-200">{this.props.message}</p>
                    </div>
                </div>
            </div>
            
    </Transition.Root> : null)
    }
}