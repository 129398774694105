import React from 'react';
import { Router, Route, Switch} from 'react-router-dom';

import { LoginPage } from './pages/Login';
import { authenticationService } from './internals/authservice/authentication.service';
import ClassCalendar from './pages/calendar/Calendar';
import TopBar from './internals/nav/TopBar';
import { history } from './internals/nav/_helpers';
import { PrivateRoute } from './internals/authservice/privateroute.comp';
import Landing from './pages/landing/Landing';
import AttendancesCalendar from "./pages/calendar/AttendancesCalendar";
import {Profile} from "./pages/user/Profile";
import {Accesos} from "./pages/user/Accesos";
import Footer from "./internals/nav/Footer";
import CmsContent from "./internals/cms/CmsContent";
import ScrollToTop from "./internals/nav/ScrollToTop";


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };

    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => {this.setState({ currentUser: x }); console.info(x);console.info("helooo");});
        authenticationService.refresh();

    }

    logout() {
        authenticationService.logout();
    }

    render() {
        return (
            <Router history={history}>
                <TopBar />
                <div>
                    <ScrollToTop/>
                    <Switch>

                        <Route path="/login"><LoginPage /></Route>
                        <Route path="/blog/:slug" component={CmsContent}/>

                        <PrivateRoute path="/calendario" component={ClassCalendar} />
                        <PrivateRoute path="/lista" component={AttendancesCalendar}/>
                        <PrivateRoute path="/perfil" component={Profile}/>
                        <PrivateRoute path="/accesos" component={Accesos}/>
                        <Route path="/"><Landing /></Route>
                    </Switch>
                </div>
                <Footer/>
            </Router>
        );
    }
}

export { App };