import React, {useEffect, useRef} from "react";
import Logo from "../assets/images/high-res-moai.png"
import ReactPlayer from 'react-player'

function HeroVideo() {

    const playerRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {

        }, 5000); // 5000ms delay, adjust as needed

        return () => clearTimeout(timer);
    }, []);

    const rootStyle = {
        width: '100%',
        position: 'relative',
        '& video': {
            objectFit: 'cover',
        },
        overflowX: 'hide'
    }

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    }

    return (
        <div className="overflow-y-hidden mb-16 shadow-2xl">
            <div className="md:h-96">
                <div style={rootStyle}>
                    <ReactPlayer
                        ref={playerRef}
                        url='https://moaiwebsite.s3.amazonaws.com/documents/Retocado.webm'
                        loop
                        muted
                        playing
                        controls={false}
                        width="100%"
                        height="100%"
                        type='video/webp'
                    />
                    <div
                        style={overlayStyle}
                        >
                        <div className="relative rounded-lg container mx-auto flex flex-col items-center">
                            <div className="sm:mt-14 relative justify-center flex">
                                <img src={Logo} alt="logo" role="img" className="max-w-xs sm:max-w-max" style={{ maxWidth: '19rem' }} />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}
export default HeroVideo;
