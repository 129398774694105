import { Switch } from '@headlessui/react'
import React from 'react'

export class DiaSemana extends React.Component {
    constructor(props) {
        super(props)
        this.state = {enabled: true}
        this.toggleState = this.toggleState.bind(this)
    }

    toggleStateNoProp(ev) {
        this.setState({enabled: ev})
    }

    toggleState() {
        this.setState({enabled: !this.state.enabled})
        this.props.onChange(this.state.enabled)
    }

    render() {
        return (<Switch.Group>
            <div className="flex items-center ml-auto pl-8">
            <Switch.Label className="ml-2 mr-4">Ver semana</Switch.Label>
            <Switch
                checked={this.state.enabled}
                onChange={this.toggleState}
                className={`${
                this.state.enabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
                <span
                className={`${
                    this.state.enabled ? 'translate-x-6' : 'translate-x-1'
                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
            </Switch>
            </div>
        </Switch.Group>)
    }
}

export default DiaSemana;