import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';
import React from 'react';

import 'tui-calendar/dist/tui-calendar.css';

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { handleResponse, authHeader } from '../../internals/authservice/authentication.service.js';
import {ButtonIcon} from '../../components/button.js';
import { SuccessPopClass } from '../../components/popups.js';
import DetailModal from './DetailSchedule';

import myTheme from './theme';
import DiaSemana from './ToggleDiaSemana';


var Constants = require('../../internals/constants');

class ScheduleInfo {
    constructor() {
        this.id = null;
        this.calendarId = null;

        this.title = null;
        this.body = null;
        this.isAllday = false;
        this.start = null;
        this.end = null;
        this.category = '';
        this.dueDateClass = '';

        this.color = null;
        this.bgColor = null;
        this.dragBgColor = null;
        this.borderColor = null;
        this.customStyle = 'maysarona';

        this.isFocused = false;
        this.isPending = false;
        this.isVisible = true;
        this.isReadOnly = false;
        this.goingDuration = 0;
        this.comingDuration = 0;
        this.recurrenceRule = '';
        this.state = '';

        this.raw = {
            memo: '',
            hasToOrCc: false,
            hasRecurrenceRule: false,
            location: null,
            class: 'public', // or 'private'
            creator: {
                name: '',
                avatar: '',
                company: '',
                email: '',
                phone: ''
            }
        };
    }

}

export class ClassCalendar extends React.Component {


    constructor(props) {

        super(props);

        this.calendarRef = React.createRef();
        this.buttonRef = React.createRef();
        this.modalRef = React.createRef();


        this.popupRef = React.createRef();
        this.scheduleList = []
        this.state = {
            show: true
        }
        this.requestOptions = { method: 'GET', headers: authHeader() };

    }

    componentDidMount() {
        this.setSchedules();
        window.addEventListener("resize", this.resize.bind(this), true);
        this.resize();

    }


    resize = () => {
        let smallScreen = (window.innerWidth <= 760);
        this.toggleView(smallScreen);
        
        
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this), true);
    }


    setSchedules = () => {
        let calendar = this.calendarRef.current.getInstance()
        console.info(calendar.getDate());
        var date = calendar.getDate();


        fetch(`${Constants.api_url}/clases/${date.getFullYear()}/${date.getMonth() + 1}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => this.generateSchedule(data))


    }


    createUpdateEvent(x) {
        var schedule = new ScheduleInfo();
        schedule.id = x.id;
        schedule.calendarId = 1;
        schedule.title = `${x.nombre_clase} (${x.apuntados}/${x.aforo} participantes)`;
        var startDate = moment(`${x.fecha}T${x.hora_inicio}`)
        var endDate = moment(`${x.fecha}T${x.hora_fin}`)
        schedule.start = startDate.toDate();
        schedule.end = endDate.toDate();

        schedule.body = {
            nombre_clase: x.nombre_clase,
            coste_creditos: x.coste_creditos,
            apuntados: x.apuntados,
            aforo: x.aforo,
            clase_id: x.id,
            dia: x.fecha,
            hora: x.hora_inicio,
            fin: x.hora_fin,
            endDate: endDate.toDate(),
        }

        schedule.isReadOnly = true;
        schedule.category = 'time';
        
        schedule.isPending = x.apuntados >= x.aforo || endDate.toDate() < moment().toDate()// TODO Si esta llena ya a true


        let color =x.apuntados / x.aforo

        switch (true) {
            case color === 0:
                schedule.bgColor = '#34D399'
                break;
            case 0 < color && color < 0.5:
                schedule.bgColor = '#FBBF24'
                break;
            case color >= 0.5:
                schedule.bgColor = '#EF4444'
                break;
            default:
                schedule.bgColor = '#34D399'
                break;

        }


        schedule.customStyle = "padding-top: 8px; font-size: 2em !important; content: 'imin'";

        //schedule.bgColor = '#5B21B6'
        if ((x.hasOwnProperty('user_inside') && x.user_inside === 1) ||
            (x.hasOwnProperty('current_user_in') && x.current_user_in)) {
            schedule.bgColor = '#5B21B6'
            schedule.title = `<span>${x.nombre_clase} - APUNTADO</span>`;
            schedule.body = {...schedule.body, user_inside: true}
        }
        
        schedule.additionalData = 'yes'
        schedule.body = JSON.stringify(schedule.body)

        return schedule
    }

    generateSchedule(data) {
        let scheduleList = []
        console.info(data)
        data.forEach((x) => {
            let currevent = this.createUpdateEvent(x);
            scheduleList.push(currevent);
        })

        let calendar = this.calendarRef.current.getInstance();
        calendar.clear();
        calendar.createSchedules(scheduleList);

    }

    toggleView = (ev) => {      
        
        if (this.calendarRef !== null && this.calendarRef.current !== null) {
        
            let calendar = this.calendarRef.current.getInstance();
            if ( calendar === null || calendar === undefined){
                return;
            }
            calendar.changeView(ev ? 'day' : 'week', true);
            this.buttonRef.current.toggleStateNoProp(!ev);
        
        }
    }

    calendarMove = (movement) => {
        let calendar = this.calendarRef.current.getInstance();
        switch (movement) {
            case 'next':
                calendar.next()
                break;
            case 'prev':
                calendar.prev();
                break;
            case 'today':
                calendar.today();
                break;
                default:
                calendar.today();    
                break;
        }

    }

     clickedSchedule = (scheduleInfo) => {
        const today = moment().toDate();
        if (scheduleInfo.schedule.end > today) {
            this.modalRef.current.passClassInfo(JSON.parse(scheduleInfo.schedule.body))
        }
    }

    apuntarseClase = (class_id, user_inside) => {
        if ((user_inside !== null || true) && user_inside) {
            fetch(`${Constants.api_url}/clases/leave/${class_id}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => {
               if (data.status !== 1) {
                   this.setState({...this.state, message: 'Ha habido un error desapuntándote de clase', titleMessage: 'Error', errorMessage:true})
               } else {
                   let mensaje = 'Desapuntado correctamente. ';
                   if (data.returned > 0)
                       mensaje = mensaje + `Te hemos devuelto ${data.returned} crédito/s.`
                       
                   this.setState({...this.state, message: 'Esperamos volver a verte pronto', titleMessage: mensaje, errorMessage:false})
               }
               this.setSchedules();
            });
        } else {
          fetch(`${Constants.api_url}/clases/join/${class_id}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => {
               if (data.status !== 1) {
                   this.setState({...this.state, message: 'No se te ha apuntado a la clase, te faltan créditos', titleMessage: 'Error', errorMessage:true})
               } else {
                   this.setState({...this.state, message: 'Te esperamos!', titleMessage: 'Apuntado!', errorMessage:false})
               }
               this.setSchedules();
            });
        }

        this.showPopup();

    }


    showPopup = () => {
        this.popupRef.current.toggle()
    }


    render(modal = <DetailModal ref={this.modalRef} apuntarseClase={this.apuntarseClase}/>) {
        
        return (<> <div className="md:mx-40 mb-2 mt-2 p-1 mx-auto flex justify-between flex-wrap">
                <div className="flex justify-left fixed text-sm sm:static float bottom-2 right-1 gap-2 rounded-xl z-50">
                    <ButtonIcon text="Anterior" onClick={() => this.calendarMove('prev')} icono="Left"/>
                    <ButtonIcon text="Hoy" onClick={() => this.calendarMove('today')} icono="Down"/>
                    <ButtonIcon text="Siguiente" onClick={() => this.calendarMove('next')} icono="Right"/>
                </div>
                <DiaSemana ref={this.buttonRef} onChange={this.toggleView} />
            </div>
            <Calendar
                
                ref={this.calendarRef}

                onBeforeCreateSchedule={this.toggleView}
                usageStatistics={false}
                calendars={[{
                    id: '1',
                    name: 'Calendario',
                    color: '#ffffff',
                    bgColor: '#9e5fff',
                    dragBgColor: '#9e5fff',
                    borderColor: '#9e5fff',
                }]}
                view={'week'}
                disableDblClick={true}
                disableClick={true}
                isReadOnly={true}
                month={{
                    daynames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                    startDayOfWeek: 1,
                    narrowWeekend: true,
                }}
                week={{
                    daynames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                    startDayOfWeek: 1,
                    workweek: true,
                    narrowWeekend: true,
                    hourStart: 10,
                    hourEnd: 23
                }}
                height={692}
                schedules={this.scheduleList}
                scheduleView={['time']}
                taskView={false}
                template={{
                    milestone(schedule) {
                        return `<span style="color:#fff;background-color: ${schedule.bgColor};">${schedule.title
                            }</span>`;
                    },
                    milestoneTitle() {
                        return 'Milestone';
                    },
                    allday(schedule) {
                        return `${schedule.title}<i class="fa fa-refresh"></i>`;
                    },
                    alldayTitle() {
                        return 'All Day';
                    },
                    timegridDisplayPrimaryTime(time) {
                        return `<span class="text-md font-semibold">${time.hour + ':' + String(time.minutes).padStart(2, '0')}</span>`

                    },
                    
                    taskTitle() {
                        return "-"
                    },

                    task(schedule) {

                        // use another functions instead of 'schedule'
                        // milestone: function() {...}
                        // task: function() {...}
                        // allday: function() {...}
            
                        var tpl;
                        
                        switch(schedule.category) {
                            case 'milestone':
                                tpl = '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + schedule.bgColor + '">' + schedule.title + '</span>';
                                break;
                            case 'task':
                                console.info('task task')
                                tpl = '#' + schedule.title;
                                break;
                            case 'allday':
                            default:
                                break;

                        }

                        return tpl
                    },
            
                        

                    monthDayName(dayname) {
                        return '<span class="calendar-week-dayname-name">' + dayname.label + '</span>';
                    }
                }}
                theme={myTheme}

                onClickSchedule={this.clickedSchedule}
                useCreationPopup
            />
            { modal }
        <SuccessPopClass message={this.state.message} title={this.state.titleMessage} error={this.state.errorMessage} ref={this.popupRef} />
        </>)
    }




}



export default ClassCalendar;