/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import moment from 'moment'
import 'moment/locale/es';
import * as Constants from "../../internals/constants";
import {authHeader, handleResponse} from "../../internals/authservice/authentication.service";


export default class ListModal extends React.Component{
  constructor(props) {

      super(props)
      this.requestOptions = { method: 'GET', headers: authHeader() };
      this.state = {open: false,
        dia: '--/--/----',
        apuntados_set: [[], [], [], [], [], [], [], [], [], [], []],
      }
      this.cancelButtonRef = React.createRef()
      this.apuntarseRef = React.createRef()
  }

  passClassInfo = (class_info) => {
    console.info('class info')
    console.info(class_info)



    this.setState({...this.state,
        ...class_info,
        open: true,

    })
  }

  setOpen = () => {
      this.setState({
          open: false,
        })
  }

  alterAsistencia = (asistencia_id) => {
      console.info(asistencia_id)
       fetch(`${Constants.api_url}/clases/list/toggle/${asistencia_id}`, this.requestOptions)
            .then(handleResponse)
            .then(() => {
                console.info('Changed?');
            })
  }



  render() {

    return (
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={this.apuntarseRef}
            open={this.state.open}
            onClose={this.setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block bg-gray-50 align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className=" bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start sm:justify-center">

                      <div className="mt-3 text-center sm:mt-0  sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
                          {this.state.titulo}
                        </Dialog.Title>
                        <div className="mt-2 font-semibold text-gray-600 text-sm grid grid-cols-6">
                            <span className="font-semibold  col-span-2">Apuntados</span>
                            <span className="font-regular col-span-1">{this.state.apuntados_set?.length}</span>

                        </div>
                        <div className="mt-2">
                            {this.state.apuntados_set.length ?
                                <div className="container mx-auto px-4 sm:px-8 max-w-full">
                                    <div className="pt-2">
                                        <div className="-mx-4 sm:-mx-8 py-4 overflow-x-auto">
                                            <div className="inline-block min-w-full overflow-hidden  rounded-lg">
                                                <table className="min-w-full leading-normal">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm font-normal">
                                                            Cliente
                                                        </th>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm  font-normal">
                                                            Asistencia
                                                        </th>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm  font-normal">
                                                            Hora entrada
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.apuntados_set.map((apuntado, i) => {
                                                    return (
                                                        <tr id={i}>
                                                            <td className="py-2 px-2 border-b border-gray-200 bg-white text-sm">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        <a href="#" className="block relative">
                                                                            <img alt="profil" src="https://via.placeholder.com/50"
                                                                                 className="mx-auto object-cover rounded-full h-10 w-10 "/>
                                                                        </a>
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                                            {apuntado.usuario?.nombre}{apuntado.usuario?.apellidos}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">
                                                                        <input type="checkbox"
                                                                               className="form-checkbox h-5 w-5 text-red-600"
                                                                               defaultChecked={apuntado.asistencia}
                                                                               onChange={() => {this.alterAsistencia(apuntado.id)}}
                                                                        />

                                                                </p>
                                                            </td>
                                                            <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">

                                                                    {apuntado?.hora_entrada ? moment(apuntado?.hora_entrada.entrada).toDate().toLocaleTimeString('es-ES') : '--:--'}
                                                                </p>
                                                            </td>
                                                    </tr>

                                                    )})}
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             : 'No hay usuario apuntados'
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">


                    <button
                      type="button"
                      className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 ring-2 ring-offset-2 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={() => this.setOpen(false)}
                      ref={this.cancelButtonRef}
                    >
                        Cerrar
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )
    }
}