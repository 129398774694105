import React, {Fragment} from 'react';
import * as Constants from "../../internals/constants";
import {authenticationService, authHeader, handleResponse} from "../../internals/authservice/authentication.service";
import Escaladores from "../../assets/images/Escaladores.jpg";

function Capitalize(str){
    if (str)
        return str.charAt(0).toUpperCase() + str.slice(1)
    else
        return '-'
}

function Format(str, def='-') {
    return str ? str : def;
}

function Gender(str) {
    if (!str) return '-'
    if (str === 'NO') return '-';
    return str === 'HO' ? 'Hombre' : 'Mujer';
}

export class Profile extends React.Component {
    constructor(props) {
        super(props);
        let requestOptions = { method: 'GET', headers: authHeader() };

        this.state = {}
    }

    componentDidMount() {
        let requestOptions = { method: 'GET', headers: authHeader() };
        fetch(`${Constants.api_url}/user/profile`, requestOptions).then(handleResponse).then(data =>  this.setState({...data}));
    }

    render() {
        return (
            <Fragment>
                <div className="container mx-auto my-5 p-5">
                    {this.state.hasOwnProperty('email') &&
                <div className="md:flex no-wrap md:-mx-2 ">

                    <div className="w-full md:w-3/12 md:mx-2">

                        <div className="bg-white p-3 border-t-4 border-green-400 rounded-sm">
                            <div className="image overflow-hidden rounded-md">
                                <img src={Escaladores}/>
                            </div>
                            <h1 className="text-gray-900 font-bold text-xl leading-8 my-1 overflow-auto">{Format(this.state.nombre)} ({Capitalize(this.state.username)})</h1>
                            <ul
                                className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                <li className="flex items-center py-3">
                                    <span>Membresía Activa</span>
                                    <span className="ml-auto"><span
                                        className="bg-green-500 py-1 px-2 rounded text-white text-sm">Activa</span></span>
                                </li>
                                <li className="flex items-center py-3">
                                    <span>Miembro desde</span>
                                    <span className="ml-auto">{this.state.fecha_alta}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="my-4"></div>

                    </div>

                    <div className="w-full md:w-9/12 mx-2">


                        <div className="bg-white p-3 shadow-sm rounded-sm">
                            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                <span clas="text-green-500">
                                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                    </svg>
                                </span>
                                <span className="tracking-wide">Datos privados del Cliente</span>
                            </div>
                            <div className="text-gray-700">
                                <div className="grid md:grid-cols-2 text-sm">
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Nombre</div>
                                        <div className="px-4 py-2">{Capitalize(this.state.nombre)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Apellidos</div>
                                        <div className="px-4 py-2">{Capitalize(this.state.apellidos)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">DNI/NIF</div>
                                        <div className="px-4 py-2">{Format(this.state.dni)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Género</div>
                                        <div className="px-4 py-2">{Gender(this.state.genero)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Teléfono</div>
                                        <div className="px-4 py-2">{Format(this.state.phone)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Dirección</div>
                                        <div className="px-4 py-2">{Format(this.state.direccion)}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Email</div>
                                        <div className="px-4 py-2">
                                            <a className="text-blue-800">{Format(this.state.email)}</a>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Cumpleaños</div>
                                        <div className="px-4 py-2">{Format(this.state.birthday)}</div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
                                ¿Quieres actualizar algún dato? Contacta con recepción.
                            </button>
                        </div>


                        <div className="my-4"></div>


                        <div className="bg-white p-3 shadow-sm rounded-sm">

                            <div className="grid grid-cols-1">
                                <div>
                                    <div
                                        className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                        <span className="text-green-500">
                                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24"
                                                 stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                            </svg>
                                        </span>
                                        <span className="tracking-wide">Últimas membresías</span>
                                    </div>
                                    <ul className="list-inside space-y-2">
                                        {this.state.hasOwnProperty('membresias') && this.state['membresias'].map((membresia) => {
                                            return <li>

                                                <div className="text-teal-600">Membresía</div>
                                                <div
                                                    className="text-gray-500 text-md">{membresia.fecha_inicio} - {membresia.fecha_fin}</div>
                                                <a className="text-green-600 text-sm" href={Constants.api_url + '/invoice/' + `${membresia.id}?tk=${authenticationService.currentUserValue}` }>Consultar factura</a>

                                            </li>
                                        })}

                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                }
                </div>

            </Fragment>
        )
    }
}