import React from "react";
import Logo from "../assets/images/high-res-moai.png";
import Background from "../assets/images/background.svg";
import { ButtonIcon } from "./button";
import { useHistory } from "react-router-dom";

function Hero({
                  titulo = 'Escalar es mucho más que ir a entrenar',
                  imagen = { large: { url: Background } },
                  boton = null,
                  show_logo= null,
              }) {
    const history = useHistory();
    const buttonAction = () => history.replace(boton.click_dir);

    return (
        <div className="overflow-y-hidden">
            <dh-component>
                <div
                    className={`w-full px-6 pl-2 pr-2 bg-cover bg-center flex items-center justify-center ${!!show_logo ? "md:h-full" : "h-32 md:h-72"}`}
                    style={{ backgroundImage: `url(${imagen.large.url})` }}
                >
                    <div className="relative rounded-lg container mx-auto flex flex-col items-center">
                        {!!show_logo && <div className="mt-6 relative justify-center flex">
                            <img src={Logo} alt="logo" role="img" className="max-w-xs sm:max-w-max" style={{ maxWidth: '19rem' }} />
                        </div>}

                        <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                            <h1 className="text-5xl lg:text-5xl xl:text-6xl text-center font-bold leading-tight negro-pero-no-mucho"
                                style={{textShadow: "0px 0px 10px rgba(255, 255, 255, 1)"}}
                            >
                                {titulo}
                            </h1>
                        </div>

                        {!boton.button_text === "" && <div className="flex justify-center items-center mb-8 sm:mb-8 pb-4">
                            <ButtonIcon text={boton.button_text} onClick={buttonAction} icono='Right' />
                        </div> }
                    </div>
                </div>
            </dh-component>
        </div>
    );

}
export default Hero;
