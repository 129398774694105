import React from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {authenticationService} from '../internals/authservice/authentication.service';
import {LockClosedIcon} from '@heroicons/react/outline';
import logo from '../assets/images/logo.png'
import {history} from '../internals/nav/_helpers';
import * as constants from "../internals/constants";
import {SuccessPopClass} from "../components/popups";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      history.push('/')
    }
    this.popupRef = React.createRef();
  }

  render() {
    return (
      <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mt-8 w-full space-y-8">
          <div>
            <img
              className="mx-auto h-30 w-auto mb-4"
              src={logo}
              alt="Workflow"
            />
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required('Nombre de usuario requerido'),
                password: Yup.string().required('Contraseña requerida')
              })}
              onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                setStatus();
                authenticationService.login(username, password)
                  .then(
                    () => {
                      history.push('/');
                    },
                    error => {
                      setSubmitting(false);
                      setStatus(true);
                      this.popupRef.current.toggle()
                    }
                  );
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <SuccessPopClass title="Error iniciando sesión" message="El usuario o la contraseña son incorrectos." error={true} ref={this.popupRef}/>
                      <label htmlFor="username" className="sr-only">
                        Usuario o Email
                      </label>
                      <Field
                        id="username"
                        name="username"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        placeholder="Usuario o Email"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                       </label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        placeholder="Contraseña"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group mt-4 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <LockClosedIcon className="h-5 w-5 text-blue-300 group-hover:text-blue-400" aria-hidden="true" />
                      </span>
                      Iniciar Sesión
                    </button>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm flex self-center mt-4 mb-4">
                      <a href={`${constants.api_url}/cuenta/password_reset/`} className="font-medium text-blue-400 hover:text-blue-500">
                        Recuperar contraseña
                      </a>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>

        </div>
      </div>
    )
  }
}

export { LoginPage };